<template>
    <nav class="navbar navbar-expand-lg" style="background:transparent;position:absolute;width:100%;top:0;left:0;">
    <div class="container-fluid d-flex" style="
    justify-content: flex-end;">
      <!-- Telegram Logo with Link -->
      <a class="navbar-brand me-3" href="https://t.me/LifeElixirAi" target="_blank">
        <img src="/telegram.png" alt="X Logo" style="width:50px;">
      </a>
      <!-- X (Twitter) Logo with Link -->
      <a class="navbar-brand " href="https://x.com/LifeElixirAi" target="_blank">
        <img src="/x.png" alt="X Logo" style="width:50px;">
      </a>
    </div>
  </nav>

<div class="container-fluid" style="background:url(/bg.png);
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;">
    
    <div class="container" 
    ><img src="/lifeelixir.png" style="
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    display: block;
">
    </div>
    <div class="container">
      <div class="row">
        <!-- Column 1: Upload Image -->
        <div class="col-4">
          <h5 class="text-center text-white">Upload a photo</h5>
          <div style="background: #40196d;border: 4px dashed rgb(243, 101, 235);border-radius: 15px;width: 100%;height: 200px;margin-left: auto;margin-right: auto;">

            <input type="file" class="imginput" @change="onFileChange" :disabled="isGenerating" style="color:white;
    margin-left: auto;
    max-width: 100%;
    display: block;cursor:pointer;"/>
          <p v-if="imageUrl" style="display:block;text-align:center;margin-left:auto;margin-right:auto;color:white;">Image uploaded successfully.</p>

          </div>

          
        </div>
  
        <!-- Column 2: Prompt Text -->
        <div class="col-4">
          <h5 class="text-center text-white">Describe what should the animation should do </h5>
          <textarea v-model="promptText" :disabled="isGenerating" style="    background: rgb(64, 25, 109);
    border: 4px dashed rgb(243, 101, 235);
    border-radius: 15px;
    width: 100%;
    min-height: 100px;
    font-size: 1.3em;
    margin-left: auto;
    margin-right: auto;
    color: white;"></textarea>

<h3 class="text-center text-white
    ">In Queue: {{ queueCount }}
</h3>

          <button @click="generate" :disabled="!canGenerate" style="border: solid 2px #f365eb;
    border-radius: 10px;
    margin-top: 10px;
    font-size: 1.5em;
    color: white;
    background: #40196d;
    box-shadow: none;
    margin-left: auto;
    margin-right: auto;
    display: block;
    cursor: pointer;">Generate</button>
        </div>
  
        <!-- Column 3: Status -->
        <div class="col-4">
          <h5 class="text-center text-white">Get a video</h5>
          <div style="background: #40196d;border: 4px dashed rgb(243, 101, 235);border-radius: 15px;width: 100%;min-height: 200px;margin-left: auto;margin-right: auto;color:white;">

            <p v-if="isGenerating" style="padding:10px;display:block;font-size: 1.3em;">Generating... Can take up to 2 minutes. <span class="loader"></span>
            </p>
          <div v-if="videoUrl">
            <h3>Result</h3>
            <video :src="videoUrl" controls style="max-width: 100%;height:100%;"></video>
          </div>

</div>

          
        </div>
      </div>
    </div>

    <div class="container">
  <h1 style="margin-left:auto;margin-right:auto;color:white">Latest Videos</h1>
  <div class="row">
    <div class="col-md-4" v-for="gen in generations" :key="gen.pikaJobId">
      <div class="mb-4">
        <video :src="gen.resultUrl" muted loop autoplay style="
    border-radius: 15px;
    object-fit: contain; 
    border: dashed 4px #f365eb;
    max-width: 100%;
    height: 100%;" @click="convertVideoToGif(gen.resultUrl)" 
    ></video>
       
      </div>
    </div>
  </div>
</div>


  </div>
  <audio style="display:none;" ref="backgroundMusic" src="/music.mp3" loop></audio> <!-- Music element -->


  </template>
  
  <script>
  import axios from 'axios';


  export default {
    name: 'HomeComponent',
    data() {
      return {
      imageUrl: '',
      promptText: '',
      pikaJobId: '',
      isGenerating: false,
      queueCount: 0, // Added queueCount
      videoUrl: '',
      intervalId: null,
      generations: [] // Added generations array
    };
    },
    computed: {
      canGenerate() {
        return this.imageUrl && this.promptText && !this.isGenerating;
      }
    },

    mounted() {
  this.fetchLast30();
  this.queueIntervalId = setInterval(this.fetchQueueCount, 3000); // Fetch queue count every 3 seconds
  this.intervalId = setInterval(this.fetchLast30, 5000);
  window.addEventListener('click', this.playMusicOnClick);
},

    methods: {



      playMusicOnClick() {
    const music = this.$refs.backgroundMusic;
    
    // Try to play the music
    music.play().then(() => {
      console.log('Music is playing');
    }).catch((error) => {
      console.error('Error playing music:', error);
    });

    // Once music starts, remove the event listener to prevent further clicks from triggering play
    window.removeEventListener('click', this.playMusicOnClick);
  },

      fetchLast30() {
    axios
      .get('https://api.lifeelixir.fun/get-last-30')
      .then((response) => {
        this.generations = response.data;
      })
      .catch((error) => {
        console.error('Error fetching last 30 generations:', error);
      });
  },

  fetchQueueCount() {
      axios
        .get('https://api.lifeelixir.fun/check-queue')
        .then((response) => {
          this.queueCount = response.data.queueCount;
        })
        .catch((error) => {
          console.error('Error fetching queue count:', error);
        });
    },


      onFileChange(event) {
        const file = event.target.files[0];
        if (file) {
          const formData = new FormData();
          formData.append('image', file);
  
          axios
            .post('https://api.lifeelixir.fun/upload-image', formData)
            .then((response) => {
              this.imageUrl = response.data.imageUrl;
            })
            .catch((error) => {
              console.error('Error uploading image:', error);
            });
        }
      },
      generate() {
        this.isGenerating = true;
  
        axios
          .post('https://api.lifeelixir.fun/generate-image', {
            imageUrl: this.imageUrl,
            promptText: this.promptText
          })
          .then((response) => {
            this.pikaJobId = response.data.pikaJobId;
            this.checkGenerationStatus();
            this.intervalId = setInterval(this.checkGenerationStatus, 10000);
          })
          .catch((error) => {
            console.error('Error generating image:', error);
            this.isGenerating = false;
          });
      },
      checkGenerationStatus() {
        axios
          .get('https://api.lifeelixir.fun/fetch-generation', {
            params: { pikaJobId: this.pikaJobId }
          })
          .then((response) => {
            if (response.data.status === 'finished') {
              this.videoUrl = response.data.video.resultUrl;
              this.isGenerating = false;
              clearInterval(this.intervalId);
            }
          })
          .catch((error) => {
            console.error('Error fetching generation status:', error);
            this.isGenerating = false;
            clearInterval(this.intervalId);
          });
      }
    }
  };
  </script>
  
  <style>
  .container {
    padding: 20px;
  }
  
  .columns {
    display: flex;
  }
  
  .column {
    flex: 1;
    margin: 10px;
  }
  </style>
  